<template>
    <div>
        <div v-if="$route.path=='/groupQuestionsList' || $route.path=='/teacherGroupQuestions'" class="home row">
            <div class="home-body col-xs-12 col-sm-6 col-md-4" v-if="$route.path == '/teacherGroupQuestions' && $store.state.userInfo.identity == 1">
                <q-card class="home-option" flat>
                    <div class="shijuan-add" @click="zujuanShow('教师')">
                        <img src="../assets/imgaes/add.png" alt="">
                    </div>
                </q-card>
            </div>
            <div class="home-body col-xs-12 col-sm-6 col-md-4" v-if="$route.path=='/groupQuestionsList'">
                <q-card class="home-option" flat>
                    <div class="shijuan-add" @click="zujuanShow()">
                        <img src="../assets/imgaes/add.png" alt="">
                    </div>
                </q-card>
            </div>
            <div class="home-body col-xs-12 col-sm-6 col-md-4" v-for="(item,index) in homeList" :key="index">
                <q-card class="home-option" v-if="$store.state.paperLanguage == 'zh'" flat>
                    <q-card-section>
                        <div class="card-title">{{item.exam_name_cn}}</div>
                        <div class="card-des">
                            <p>{{item.exam_knowledge_cn}}</p>
                        </div>
                    </q-card-section>
                    <q-separator style="background: #999999"  />
                    <q-card-actions>
                        <div class="card-btn">
                            <p>
                                <span>{{item.exam_total_cn}}{{$t('home.Questions')}} / </span>
                                <span>{{item.exam_time_cn}}{{$t('home.Minutes')}} / </span>
                                <span>{{item.exam_grade_cn}}{{$t('home.Points')}}</span>
                            </p>
                            <q-btn class="card-btn-star" @click="starExam('/exam',item,$route.meta.type)" flat>{{$t('home.ViewDetail')}}</q-btn>
                        </div>
                    </q-card-actions>
                    <div class="del" v-if="$store.state.userInfo.identity == 1" @click="delExam(item.id)">
                        <img src="../assets/imgaes/del.png" alt="">
                    </div>
                </q-card>
                <q-card class="home-option" v-if="$store.state.paperLanguage == 'en'" flat>
                    <q-card-section>
                        <div class="card-title">{{item.exam_name_en}}</div>
                        <div class="card-des">
                            <p>{{item.exam_knowledge_en}}</p>
                        </div>
                    </q-card-section>
                    <q-separator style="background: #999999"  />
                    <q-card-actions>
                        <div class="card-btn">
                            <p>
                                <span>{{item.exam_total_cn}}{{$t('home.Questions')}} / </span>
                                <span>{{item.exam_time_cn}}{{$t('home.Minutes')}} / </span>
                                <span>{{item.exam_grade_cn}}{{$t('home.Points')}}</span>
                            </p>
                            <q-btn class="card-btn-star" @click="starExam('/exam',item,$route.meta.type)" flat>{{$t('home.ViewDetail')}}</q-btn>
                        </div>
                    </q-card-actions>
                    <div class="del" v-if="$store.state.userInfo.identity == 1" @click="delExam(item.id)">
                        <img src="../assets/imgaes/del.png" alt="">
                    </div>
                </q-card>
            </div>
            <!--组卷信息填写 弹出框-->
            <groupQuestionsInfo :type="$route.meta.type" v-if="$store.state.groupType" />
        </div>
        <router-view v-else></router-view>
        <!--购买弹窗-->
        <Buy></Buy>
        <!--确定删除弹窗-->
        <q-dialog v-model="del" style="" persistent transition-show="scale" transition-hide="scale">
            <q-card class="bg-orange text-white" style="width: 300px;background: #ED711C">
                <q-card-section class="pay-top">
                    <div class="text-h6">{{$t('home.Notices')}}</div>
                </q-card-section>

                <q-card-section class="q-pt-none pay-body">
                    {{$t('home.Areyousureyouwanttodeletethistestpaper')}}?
                </q-card-section>

                <q-card-actions align="right" class="bg-white text-teal" >
                    <q-btn flat class="jieshu-quxiao" style="color: #ED711C" :label="$t('home.Cancel')" v-close-popup/>
                    <q-btn flat class="jieshu-end" style="color: #ED711C" :label="$t('home.determine')" @click="isDel()" />
                </q-card-actions>
            </q-card>
        </q-dialog>
    </div>

</template>

<script>
    import groupQuestionsInfo from "../components/groupQuestionsInfo";
    import Buy from "../components/Buy";
    export default {
        name: "ManualList",
        components:{
            groupQuestionsInfo,
            Buy
        },
        data(){
            return{
                homeList:[],
                type:'',
                pay:false,
                del:false,
                examId:0
            }
        },
        created() {
            this.type = this.$route.meta.type
            this.getExamList(this.$route.meta.type)
        },
        methods:{
            // 获取试卷列表
            getExamList(type){
                this.$http.post('exam/exam_list',{
                    type:type
                }).then(res =>{
                    console.log('试卷列表',res)
                    
                    this.homeList = res.data
                     this.homeList.forEach((item,index)=>{
                         console.log(!item.exam_grade_cn)
                        if (!item.exam_grade_cn){
                             this.homeList.splice(index,1)
                            console.log('22222222222222-------------------')
                        }
                    })
                }).catch(err =>{
                    console.log(err)
                    this.homeList = []
                })
            },
            // 删除试卷
            delExam(id){
               this.examId = id
                this.del = true
            },
            isDel(){
                this.$http.post('exam/exam_del',{
                    type:this.type,
                    id:this.examId
                }).then(res =>{
                    console.log(res)
                    this.$q.notify({
                        type: 'positive',
                        position:'top',
                        timeout:'500',
                        message: this.$t('home.Deletesucceeded')
                    })
                    this.del = false
                    this.getExamList(this.type)
                }).catch(err =>{
                    console.log(err)
                    this.$q.notify({
                        type: "negative",
                        position: "top",
                        timeout: "500",
                        message: err.msg,
                    });
                })
            },
            starExam(url,item,type){
                this.$router.push({path:url,query:{id:item.id,type:type}})
                localStorage.setItem('exam_time',item.exam_time_cn)
            },

            zujuanShow(name){
                if (name == '教师'){
                    this.$http.post('user_product/check_productt_user',).then(res =>{
                        console.log(res)
                        this.$store.state.groupType = true
                    }).catch(err =>{
                        console.log(err)
                        this.$store.state.pay = true
                        this.$store.state.butType = 2
                    })
                }else {
                    this.$http.post('user_product/check_product_user',).then(res =>{
                        console.log(res)
                        this.$store.state.groupType = true
                    }).catch(err =>{
                        console.log(err)
                        this.$store.state.pay = true
                        this.$store.state.butType = 1
                    })
                }

                // if (sessionStorage.getItem('buyAi')){
                //     this.$store.state.groupType = true
                // }else if (sessionStorage.getItem('teacherBuy')){
                //     this.$store.state.groupType = true
                // }else{
                //     this.$store.state.pay = true
                // }

            }
        },
        watch:{
            $route(){
                console.log('222222222222222222-------------------',this.$route.meta.type)
                this.getExamList(this.type)
            }
        },
    }
</script>

<style scoped lang="less">
    .home {
        padding-bottom: 20px;
        padding-left: 30px;
        padding-right: 15px;
        margin-top: 20px;
    /deep/.q-card__actions .q-btn__wrapper{
        padding: 0 7px;
    }
    }
    .home-body {
        margin-bottom: 20px;
    }
    .home-option {
        background: #F4F4F4;
        /*border: 1px solid #d3d3d3;*/
        border-radius: 20px;
        margin-right: 15px;
        position: relative;
        overflow: hidden;
    .exam-trial-flag{
        position: absolute;
        width: 80px;
        font-size: 12px;
        top: -26px;
        color: #fff;
        padding-top: 40px;
        text-align: center;
        padding-bottom: 3px;
        background: #FF0000;
        box-shadow: 0 0 10px #c5c5c5;
        transform: rotate(45deg);
        right: -36px;
    }
    }
    .card-title{
        font-size: 24px;
        color: #333333;
    }
    .card-des p{
        color: #666666;
        font-size: 14px;
        height: 44px;
        line-height: 23px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 0;
        margin-top: 18px;
    }
    .card-btn{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 23px;
        padding-right: 21px;
    p{
        font-size: 14px;
        color: #999999;
        margin: 0;
    }
    .card-btn-star{
        /*width: 70px;*/
        // height: 37px;
        background: #ED711C;
        color: #ffffff;
        font-size: 14px;
        border-radius: 19px;
    }
    }
    .shijuan-add{
        width: 100%;height: 186px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .del{
        position: absolute;
        top: 20px;
        right: 20px;
        width: 18px;
        height: 18px;
        cursor: pointer;
        img{
            width: 100%;
            height: 100%;
        }
    }
</style>
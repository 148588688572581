<template>
    <div>
        <q-dialog v-model="$store.state.groupType" content-class="pop">
            <div class="pop-box">
                <q-btn class="pop-close" @click="$store.state.groupType = false" style="color: #FF0000" icon="close" flat round dense  />
                <div class="pop-title">{{$t('home.CreateExam')}}</div>
                <q-form ref="myForm"
                        @submit="onSubmit"
                        @reset="onReset"
                >
                    <q-input borderless bottom-slots   v-model="name"  lazy-rules :rules="[ verPaperName ]">
                        <template v-slot:prepend>
                            <span class="input-lable">{{$t('home.ExamName')}}*</span>
                        </template>
                        <template v-slot:hint>
                            <span class="input-hint">{{$t('home.EnterExamName')}}</span>
                        </template>
                    </q-input>
                    <q-input borderless bottom-slots  v-model.number="duration"  lazy-rules :rules="[ verDuration ]">
                        <template v-slot:prepend>
                            <span class="input-lable">{{$t('home.TimeLimit')}}*</span>
                        </template>
                        <template v-slot:hint>
                            <span class="input-hint">{{$t('home.EnterTimeLimit')}}</span>
                        </template>
                        <template v-slot:append>
                            <span style="color: #666666;font-size: 20px">{{$t('home.Minutes')}}</span>
                        </template>
                    </q-input>
                    <q-input borderless bottom-slots   v-model="num"  lazy-rules :rules="[ verPaperCnName ]">
                        <template v-slot:prepend>
                            <span class="input-lable">{{$t('home.NumberOfQuestionsS')}}*</span>
                        </template>
                        <template v-slot:hint>
                            <span class="input-hint">{{$t('home.EnterNumberOfQuestions')}}</span>
                        </template>
                    </q-input>
                    <q-select v-model="identity" bottom-slots :options="options" >
                        <template v-slot:prepend>
                            <span class="input-lable">{{$t('home.Grade')}}*</span>
                        </template>
                        <template v-slot:hint>
                            <span class="input-hint">{{$t('home.PleaseSelectYourLevel')}}</span>
                        </template>
                    </q-select>
                    <div class="pop-bottom">
                        <q-btn class="pop-btn" unelevated  flat :label="$t('home.STARTs')" type="submit" />
                    </div>
                </q-form>
            </div>
        </q-dialog>
    </div>
</template>

<script>
    import { verPaperName,verDuration,verExamName } from "../assets/Validation";
    export default {
        name: "groupQuestionsInfo",
        props:['type'],
        data(){
            return{
                pwd:true,
                name: '',
                duration:'',
                num:'',
                accept: false,
                nameCn:'',
                identity:null,
                options:[],
            }
        },
        created() {
            console.log(this.type)
            this.getBuyLeveList()
            let time = new Date()
            this.name = this.$moment(time).format('YYYYMMDD')+'_AI_F1';
        },
        methods:{
            getBuyLeveList(){
                this.$http.post('user_product/check_product_user',).then(res =>{
                    res.data.forEach(item=>{
                        this.options.push({
                            label: item.name,
                            value:item.product_type_id
                        })
                    })

                }).catch(err =>{
                    console.log(err)
                })
            },
            onSubmit () {
                console.log(this.options.value)
                this.$refs.myForm.validate().then(success => {
                    if (success) {
                        this.$http.post('exam/generate_exam',{
                            type:this.type,
                            exam_name_cn:this.name,
                            exam_name_en:this.name,
                            exam_time:this.duration,
                            exam_total:this.num,
                            product_type_id:this.identity.value,
                        }).then(res =>{
                            console.log(res)
                            this.$store.state.groupType = false
                            this.$q.notify({
                                type: 'positive',
                                position:'top',
                                timeout:'500',
                                message: res.msg
                            })
                            localStorage.setItem('zujuan_id',res.data.exam_id)
                            localStorage.setItem('exam_total',this.num)
                            localStorage.setItem('exam_name',this.name)
                            localStorage.setItem('exam_name_en',this.nameCn)
                            this.type === 'exam_ai' ? this.$parent.getExamList('exam_ai') : this.$router.push({path:'/teacherType'})
                        }).catch(err =>{
                            console.log(err)
                            this.$q.notify({
                                type: 'negative',
                                position:'top',
                                timeout:'500',
                                message: err.msg
                            })
                        })
                        // console.log('222')


                        // 是的，模型是正确的
                    }
                    else {
                        // 哦，不，用户至少
                        // 填写了一个无效值
                        console.log('11111')
                    }
                })
            },
            verPaperName(val){
                return new Promise((resolve) => {
                    console.log('验证结果',!verExamName(val))
                    if (verExamName(val)){
                        resolve( this.$t('home.EnterExamName'))
                    }else {
                        resolve()
                    }
                })
            },
            verPaperCnName(val){
                return new Promise((resolve) => {
                    if (!verPaperName(val)){
                        resolve( this.$t('home.EnterNumberOfQuestions'))
                    }else {
                        resolve()
                    }
                })
            },
            verDuration(val){
                return new Promise((resolve) => {
                    if (verDuration(val) === 0){
                        resolve( this.$t('home.EnterTimeLimit'))
                    }else if (verDuration(val) === 1){
                        resolve( this.$t('home.EnterTimeLimit'))
                    } else {
                        resolve()
                    }
                })
            },
            onReset () {
                this.name = null
                this.age = null
                this.accept = false
            }
        },
    }
</script>

<style scoped>

</style>